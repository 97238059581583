<script setup lang="ts">
</script>
<template>
<LayoutTopBar/>
<div class="relative pt-5 sm:pt-7 bg-white">
    <div class="sm:mx-auto container">
        <div
            class="mx-8 pt-5 pb-3 mb-5 sm:mb-0"
        >
            <div class="">
                <NuxtLink href="/">
                    <span class="sr-only">BEVCO A/S</span>
                    <img
                        class="h-auto"
                        src="/logo.svg"
                        alt="Logo"
                        width="128"
                        height="30"
                    />
                </NuxtLink>
            </div>
        </div>
    </div>
</div>
</template>
